<template>
  <div>
    <div class="title">ADS-Hub</div>
    <!-- 基本信息 -->
    <div class="margin-bottom">
      <el-row :gutter="12">
        <el-col :span="12">
          <el-card shadow="always" style="background: #68bbf1">
            <div><span>Name :</span>{{ hubinfo.name }}</div>
            <div><span>Company :</span>{{ hubinfo.company_name }}</div>
            <div>
              <span>Phone :</span>{{ hubinfo.phone
              }}<small v-if="hubinfo.phone_extension">xxxx</small>{{ hubinfo.phone_extension }}
            </div>
          </el-card>
        </el-col>
        <el-col :span="12">
          <el-card shadow="always" style="background: #72a2e0">
            <div><span>Email :</span>{{ hubinfo.email }}</div>
            <div style="display: flex">
              <span>Address :</span>
              <div>
                <div>{{ hubinfo.street1 }}</div>
                <div v-if="hubinfo.street2">{{ hubinfo.street2 }}</div>
                <div>
                  {{ hubinfo.city }}, {{ hubinfo.state }} &emsp;{{
                    hubinfo.zip
                  }}&emsp;{{ hubinfo.country }}
                </div>
              </div>
            </div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 当天包裹统计 -->
    <div class="predaynum">
      <div class="moremsg">
        <div class="title">Statistics Today</div>
        <div>
          <router-link to="/statistics">More >></router-link>
        </div>
      </div>
      <el-row :gutter="12">
        <el-col :span="3">
          <el-card shadow="always" style="background: #1ebfaf">
            <div class="num">{{ number.type_9 }}</div>
            <div class="name">Receive</div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card shadow="always" style="background: #5ea9cf">
            <div class="num">{{ number.type_1 }}</div>
            <div class="name">Enter</div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card shadow="always" style="background: #9ad256">
            <div class="num">{{ number.type_2 }}</div>
            <div class="name">Out</div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card shadow="always" style="background: #b097db">
            <div class="num">{{ number.type_3 }}</div>
            <div class="name">Measure</div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card shadow="always" style="background: #f9df37">
            <div class="num">{{ number.type_4 }}</div>
            <div class="name">Put</div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card shadow="always" style="background: #ff6e72">
            <div class="num">{{ number.type_5 }}</div>
            <div class="name">Return</div>
          </el-card>
        </el-col>
        <el-col :span="3">
          <el-card shadow="always" style="background: #fa8e68">
            <div class="num">{{ number.type_6 }}</div>
            <div class="name">Lose</div>
          </el-card>
        </el-col>
      </el-row>
    </div>
    <!-- 包裹操作-->
    <div class="title margin-top">
      <div class="moremsg">
        <div class="title">Shipment Operation</div>
        <div><router-link to="/loglist">Logs >></router-link></div>
      </div>
      <div class="tips">Please select the operation you want to do.</div>
      <el-radio-group v-model="radio" @change="chage">
        <el-radio :label="3">New shipment scan({{ newenternum }})</el-radio>
        <el-radio :label="0">In transit shipment scan({{ enternum }})</el-radio>
        <el-radio :label="1">Shipment Out({{ outnum }})</el-radio>
        <el-radio :label="2">Shipment Lose({{ losenum }})</el-radio>
      </el-radio-group>
      <div class="margin-top">
        <el-input v-model="enterMsg" placeholder="Please enter tracking id."
          @keyup.native.enter="confirmSubmit"></el-input>
      </div>
      <el-button class="margin-top" type="primary" @click="confirmSubmit">Submit</el-button>
      <el-button @click="reset">Reset</el-button>
    </div>
    <!-- 仓库条形码 -->
    <div class="title margin-top">Shelves Barcode</div>
    <div>
      <el-form :model="ruleForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item prop="codetext">
          <el-input v-model="ruleForm.codetext" placeholder="Please enter Shelf name."
            @keyup.native.enter="submitForm('ruleForm')"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">Submit</el-button>
          <el-button @click="resetForm('ruleForm')">Reset</el-button>
        </el-form-item>
      </el-form>
    </div>
    <!-- 弹框 -->
    <el-dialog title="Received. Do you want to create a new label?" icon="warning" :visible.sync="dialogVisible"
      width="50%" :before-close="handleClose">
      <el-table :data="tipMsg" style="width: 100%" height="300">
        <el-table-column prop="created_at" label="Date"> </el-table-column>
        <el-table-column prop="location" label="Location"> </el-table-column>
        <el-table-column prop="description" label="Description">
        </el-table-column>
      </el-table>

      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">Cancel</el-button>
        <el-button type="primary" @click="sureRecreate">OK</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { info, receive, enter, out, lose, createNew } from '../../api/user'
import { validateShelfname } from '@/utils/validate.js'
export default {
  data () {
    return {
      shipment_id: '',
      tipMsg: [],
      dialogVisible: false,
      newenternum: 0,
      enternum: 0,
      outnum: 0,
      losenum: 0,
      radio: '',
      enterMsg: '',
      ruleForm: {
        codetext: ''
      },
      hubinfo: {},
      role: '',
      flag: true,
      url: 'http://47.108.140.133:8030/hub/shelves/barcode',
      rules: {
        codetext: [{ validator: validateShelfname, trigger: 'blur' }]
      },
      number: {}
    }
  },
  created () {
    this.getinfo()
  },
  methods: {
    async sureRecreate () {
      const data = await createNew({
        shipment_id: this.shipment_id
      })
      if (data.status === 200) {
        this.$message({
          message: 'Successfully created,please reprint label.',
          type: 'success'
        })
        this.dialogVisible = false
        this.enterMsg = ''
      } else {
        this.$message({
          message: data.data.toperr,
          type: 'error'
        })
      }
    },
    handleClose (done) {
      this.dialogVisible = false
      done()
    },
    chage (value) {
      // console.log(value)
      this.radio = value
    },
    reset () {
      this.radio = ''
      this.enterMsg = ''
      this.enternum = 0
      this.outnum = 0
      this.losenum = 0
    },
    resetForm (formName) {
      this.$refs[formName].resetFields()
    },
    confirmSubmit () {
      if (this.radio === '') {
        this.$message({
          message: 'Please select the type of operation.',
          type: 'error'
        })
      }
      if (this.radio === 0) {
        this.entersubmit()
      }
      if (this.radio === 1) {
        this.outsubmit()
      }
      if (this.radio === 2) {
        this.losesubmit()
      }
      if (this.radio === 3) {
        this.newentersubmit()
      }
    },
    // 基本信息请求
    async getinfo () {
      const data = await info({})
      // console.log(data)
      this.hubinfo = data.hub
      this.number = data.statistics
    },
    // 新入库
    async newentersubmit () {
      if (this.flag) {
        const data = await receive({
          tracking_id: this.enterMsg
        })
        // console.log(data)
        if (data.data.operation === 1) {
          this.dialogVisible = true
          this.tipMsg = data.data.trackings
          this.shipment_id = data.data.shipment_id
        } else {
          if (data.status === 201) {
            this.enterMsg = ''
            this.number.type_9 += 1
            this.newenternum += 1
          } else {
            this.$message({
              message: data.data.toperr,
              type: 'error'
            })
            this.enterMsg = ''
          }
        }
        this.flag = false
        setTimeout(() => {
          this.flag = true
        }, 3000)
      }
    },
    // 入库
    async entersubmit () {
      if (this.flag) {
        const data = await enter({
          tracking_id: this.enterMsg
        })
        if (data.status === 201) {
          this.enterMsg = ''
          this.number.type_1 += 1
          this.enternum += 1
        } else {
          this.$message({
            message: data.data.toperr,
            type: 'error'
          })
        }
        this.flag = false
        setTimeout(() => {
          this.flag = true
        }, 3000)
      }
    },
    // 出库
    async outsubmit () {
      if (this.flag) {
        const data = await out({
          tracking_id: this.enterMsg
        })
        if (data.status === 201) {
          this.enterMsg = ''
          this.number.type_2 += 1
          this.outnum += 1
        } else {
          this.$message({
            message: data.data.toperr,
            type: 'error'
          })
        }
        this.flag = false
        setTimeout(() => {
          this.flag = true
        }, 3000)
      }
    },
    // 包裹丢失
    async losesubmit () {
      if (this.flag) {
        const data = await lose({
          tracking_id: this.enterMsg
        })
        if (data.status === 201) {
          this.enterMsg = ''
          this.number.type_6 += 1
          this.losenum += 1
        } else {
          this.$message({
            message: data.data.toperr,
            type: 'error'
          })
        }
        this.flag = false
        setTimeout(() => {
          this.flag = true
        }, 3000)
      }
    },
    // 仓库条形码
    submitForm (formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          var tempwindow = window.open('_blank')
          tempwindow.location.href =
            this.url + '?name=' + this.ruleForm.codetext
        } else {
          return false
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
/deep/.el-form-item__content {
  margin-left: 0 !important;
}

.title {
  font-size: 30px;
  font-weight: bold;
  padding-bottom: 20px;
  color: #333;
}

.el-card {
  height: 180px;
  background: #72a2e0;
  color: #fff;
  line-height: 35px;
}

.card-height {
  height: auto;
  border: 0;
}

.el-col {
  font-size: 16px;

  span {
    font-weight: bold;
    font-size: 20px;
    padding-right: 5px;
  }
}

.el-col-3 {
  width: 14.28%;
}

.margin-top {
  margin-top: 20px;
}

.margin-bottom {
  margin-bottom: 40px;
}

/deep/.el-checkbox__label {
  font-size: 18px;
}

.tips {
  font-weight: normal;
  font-size: 15px;
  color: #c0c4cc;
  margin-bottom: 20px;
  // margin-left: 15px;
}

.predaynum {
  .el-col {
    padding: 10px;
    text-align: center;

    .el-card {
      height: 98px;

      /deep/.el-card__body {
        padding: 14px;

        .num {
          font-size: 28px;
          font-weight: bold;
          color: #fff;
        }

        .name {
          font-size: 16px;
          color: #fff;
        }
      }
    }
  }
}

.moremsg {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;

  a {
    color: #409eff;
    font-weight: normal;
  }
}

/deep/.el-radio__inner {
  width: 20px;
  height: 20px;
}

/deep/.el-radio__label {
  font-size: 16px;
}
</style>
